import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  ClaimId: any;
  /** Date, given as an ISO 8601 string. Parsed as UTC if time offset is not given. */
  Date: any;
  DeviceId: any;
  /**
   * Global location number, used world wide to identify legal, physical and
   * functional locations. These are used as identifiers e.g. companies and power
   * meter ids.
   */
  Gln: any;
  IntermediaryId: any;
  JSON: any;
  MeterId: any;
  /** Nothing */
  Void: any;
};


export type Activation = {
  __typename?: 'Activation';
  status: ActivationStatus;
  updatedAt: Scalars['Date'];
};

export type ActivationStatus =
  | 'ACTIVATED'
  | 'DEACTIVATED';

export type AddChargingLocationInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
};

export type AddChargingLocationPayload = AddChargingLocationSuccessPayload | ChargingLocationAlreadyExistsPayload;

export type AddChargingLocationResponseType =
  | 'SUCCESS'
  | 'ALREADY_EXISTS';

export type AddChargingLocationSuccessPayload = {
  __typename?: 'AddChargingLocationSuccessPayload';
  chargingLocation: ChargingLocation;
  type: AddChargingLocationResponseType;
};

export type AddChargingPermissionInput = {
  userIdentifier: UserIdentifierInput;
};

export type AddChargingPermissionPayload = {
  __typename?: 'AddChargingPermissionPayload';
  modifiedPermission: Permission;
  success: Scalars['Boolean'];
  user: UserInfo;
};

export type AddControllableForLocationInput = {
  locationId: Scalars['String'];
  controllableId: Scalars['String'];
};

export type AddControllableForLocationPayload = {
  __typename?: 'AddControllableForLocationPayload';
  location: Location;
};

export type AddDevicePermissionsInput = {
  deviceIdentifiers: Array<DeviceIdentifierInput>;
  userIdentifier: UserIdentifierInput;
};

export type AddDevicePermissionsPayload = {
  __typename?: 'AddDevicePermissionsPayload';
  modifiedPermissions: Array<Permission>;
  success: Scalars['Boolean'];
  user: UserInfo;
};

export type AddEstimatedHourlyActiveEnergyLimitWarningAlertSettingInput = {
  deviceId: Scalars['String'];
  limitInWattHours: Scalars['Int'];
};

export type AddHourlyConsumptionLimitEstimationWarningAlertSettingInput = {
  deviceId: Scalars['String'];
  limitInWatts: Scalars['Int'];
};

export type AddIntermediaryApiKeyInput = {
  name: Scalars['String'];
};

export type AddIntermediaryWebhookNotificationChannelToAlertSettingInput = {
  alertSettingId: Scalars['ID'];
  callbackUrl: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
  intermediaryId?: Maybe<Scalars['String']>;
};

export type AddIntermediaryWebhookNotificationChannelToAlertSettingPayload = {
  __typename?: 'AddIntermediaryWebhookNotificationChannelToAlertSettingPayload';
  notificationChannel: IntermediaryWebhookNotificationChannel;
};

export type AddLocationInput = {
  name: Scalars['String'];
  coordinates: LatLngInput;
};

export type AddLocationPayload = {
  __typename?: 'AddLocationPayload';
  location: Location;
};

export type AddPriceWarningAlertSettingInput = {
  energyBiddingZone: EnergyBiddingZone;
};

export type AddPushNotificationChannelToAlertSettingInput = {
  alertSettingId: Scalars['ID'];
};

export type AddPushNotificationChannelToAlertSettingPayload = {
  __typename?: 'AddPushNotificationChannelToAlertSettingPayload';
  notificationChannel: PushNotificationChannel;
};

export type AddUserInput = {
  userIdentifier: UserIdentifierInput;
};

export type AddUserPayload = {
  __typename?: 'AddUserPayload';
  success: Scalars['Boolean'];
  user: UserInfo;
};

export type AddUserSessionInput = {
  userIdentifier: UserIdentifierInput;
};

export type AddUserSessionPayload = {
  __typename?: 'AddUserSessionPayload';
  session: SessionInfo;
  success: Scalars['Boolean'];
  user: UserInfo;
};

export type AlertChannelType =
  | 'PUSH_NOTIFICATION'
  | 'INTERMEDIARY_WEBHOOK';

export type AlertSetting = PriceWarningAlertSetting | HourlyConsumptionLimitEstimationWarningAlertSetting | EstimatedHourlyActiveEnergyLimitWarningAlertSetting;

export type AlertSettingCreator = EndUserAlertSettingCreator | IntermediaryAlertSettingCreator;

export type AlertSettingCreatorType =
  | 'END_USER'
  | 'INTERMEDIARY';

export type AlertType =
  | 'PRICE_WARNING'
  | 'HOURLY_CONSUMPTION_LIMIT_ESTIMATION_WARNING'
  | 'ESTIMATED_HOURLY_ACTIVE_ENERGY_LIMIT_WARNING';

export type CancelOrderInput = {
  orderId: Scalars['String'];
};

export type CapacityModelType =
  | 'MonthlyPeaksAtDifferentDays'
  | 'WeightedYearlyRollingPeaksInDifferentWeeks'
  | 'MonthlyPeaksAtDifferentDaysLinearWithBase'
  | 'FuseAndVoltageSize';

/** The capacity steps includes as range in Wh and a price for the capacity step */
export type CapacityStep = {
  __typename?: 'CapacityStep';
  rangeInWh: EnergyRangeInWh;
  capacityStepPrice: PriceSpecification;
};

export type ChargeCost = {
  __typename?: 'ChargeCost';
  savings: Scalars['Float'];
  cost: Scalars['Float'];
  currency?: Maybe<Currency>;
};

export type ChargingHistoryEntry = {
  __typename?: 'ChargingHistoryEntry';
  startTime: Scalars['Date'];
  endTime: Scalars['Date'];
  chargedEnergyInKwh: Scalars['Float'];
};

export type ChargingLocation = {
  __typename?: 'ChargingLocation';
  id: Scalars['ID'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
};

export type ChargingLocationAlreadyExistsPayload = {
  __typename?: 'ChargingLocationAlreadyExistsPayload';
  type: AddChargingLocationResponseType;
};

export type ChargingSession = {
  __typename?: 'ChargingSession';
  sessionId: Scalars['String'];
  chargingLocationId: Scalars['String'];
  isSmartSession: Scalars['Boolean'];
  energy: Array<Maybe<ChargingHistoryEntry>>;
  chargeCost: ChargeCost;
};

export type ChargingStatisticsEntry = {
  __typename?: 'ChargingStatisticsEntry';
  kw: Range;
  kwhSum: Scalars['Float'];
  price: Range;
  nonSmartPrice: Range;
  costSum: Scalars['Float'];
  estimatedSavings: Scalars['Float'];
  date: Scalars['String'];
  smartSession: Scalars['Boolean'];
  priceCurrency: Currency;
};

export type ChargingStatisticsInput = {
  startDate: Scalars['Date'];
  endDate?: Maybe<Scalars['Date']>;
  type?: Maybe<ChargingStatisticsType>;
  resolution?: Maybe<ChargingStatisticsResolution>;
  id?: Maybe<Scalars['String']>;
  utcOffset?: Maybe<Scalars['Float']>;
  chargingLocationId?: Maybe<Scalars['String']>;
};

export type ChargingStatisticsPayload = {
  __typename?: 'ChargingStatisticsPayload';
  entries: Array<ChargingStatisticsEntry>;
};

export type ChargingStatisticsResolution =
  | 'HALF_HOUR'
  | 'HOUR'
  | 'DAY'
  | 'WEEK'
  | 'MONTH'
  | 'YEAR';

export type ChargingStatisticsType =
  | 'VEHICLE'
  | 'CHARGER'
  | 'HVAC';

export type CheckJedlixConnectionInput = {
  connectionId: Scalars['String'];
};


export type CommonSignInInput = {
  username: Scalars['String'];
  password: Scalars['String'];
  vendor: CommonSignInVendor;
};

export type CommonSignInPayload = {
  __typename?: 'CommonSignInPayload';
  success: Scalars['Boolean'];
};

export type CommonSignInVendor =
  | 'MILL';

export type ConfiguredIntegrationVendorDevice = ConfiguredIntegrationVendorVehicle;

export type ConfiguredIntegrationVendorInfo = {
  __typename?: 'ConfiguredIntegrationVendorInfo';
  status: IntegrationVendorStatus;
  vendor: IntegrationVendor;
  devices: Array<ConfiguredIntegrationVendorDevice>;
};

export type ConfiguredIntegrationVendorVehicle = {
  __typename?: 'ConfiguredIntegrationVendorVehicle';
  id: Scalars['ID'];
  vendor: IntegrationVendor;
  model: Scalars['String'];
  yearOfProduction: Scalars['Int'];
  isReachable: Scalars['Boolean'];
  isSmartChargingCapable: Scalars['Boolean'];
  chargingLocationId?: Maybe<Scalars['String']>;
  chargeState: ConfiguredIntegrationVendorVehicleChargeState;
  location: ConfiguredIntegrationVendorVehicleLocationState;
  smartChargingPolicy?: Maybe<SmartChargingPolicy>;
};

export type ConfiguredIntegrationVendorVehicleChargeState = {
  __typename?: 'ConfiguredIntegrationVendorVehicleChargeState';
  batteryCapacity: Scalars['Float'];
  batteryLevel: Scalars['Int'];
  chargeRate: Scalars['Float'];
  chargeTimeRemaining: Scalars['Int'];
  isCharging: Scalars['Boolean'];
  isPluggedIn: Scalars['Boolean'];
  range: Scalars['Float'];
  isFullyCharged: Scalars['Boolean'];
};

export type ConfiguredIntegrationVendorVehicleLocationState = GpsNeverActivatedConfiguredIntegrationVendorVehicleLocationState | GpsDisabledConfiguredIntegrationVendorVehicleLocationState | LocationAvailableConfiguredIntegrationVendorVehicleLocationState;

export type ConfiguredIntegrationVendorVehicleLocationStateType =
  | 'GPS_NEVER_ACTIVATED'
  | 'GPS_DISABLED'
  | 'LOCATION_AVAILABLE';

export type ConnectExternalUserInput = {
  userIdentifier: UserIdentifierInput;
};

export type ConnectExternalUserPayload = {
  __typename?: 'ConnectExternalUserPayload';
  /** Devices shipped to the user. If no devices have been shipped yet, the list is empty. Use the `ordersForUser` query to know the status of the user's orders. */
  devices: Array<DeviceInfo>;
  /** The sessions object contains an `accessToken` to be used by apps to run queries on behalf of the external user. This token will have permissions to get data for the devices shipped to the user. */
  session: SessionInfo;
  success: Scalars['Boolean'];
  user: UserInfo;
};

export type ConsideringSmartChargingStatus = {
  __typename?: 'ConsideringSmartChargingStatus';
  type: SmartChargingStatusType;
  consideration: SmartChargingConsideration;
  externalStart: Scalars['Boolean'];
};

/** Has the same price for all times of the day, week and year */
export type ConstantPrice = {
  __typename?: 'ConstantPrice';
  type: EnergyModelType;
  pricesAndTaxes: PricesAndTaxes;
  rushPricing?: Maybe<Array<Maybe<RushPricingPeriod>>>;
};

export type Controllable = {
  __typename?: 'Controllable';
  id: Scalars['String'];
  intermediaryId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type: ControllableType;
  vendor: Vendor;
  ownerId: Scalars['String'];
};

export type ControllableType =
  | 'ROOM';

export type ControllablesForUserPayload = {
  __typename?: 'ControllablesForUserPayload';
  controllables: Array<Controllable>;
};

export type CountryCode =
  | 'NO';

export type CreateJedlixChargingLocationInput = {
  coordinates: LatLngInput;
  address: JedlixAddressInput;
};

export type CreateJedlixUserInput = {
  locale: Scalars['String'];
  defaultCountry: CountryCode;
};

export type CreationInfo = {
  __typename?: 'CreationInfo';
  by: UserInfo;
  at: Scalars['Date'];
};

export type Currency =
  | 'NOK';

export type CurrentIntermediaryPayload = {
  __typename?: 'CurrentIntermediaryPayload';
  alertSettings?: Maybe<Array<AlertSetting>>;
};


export type CurrentIntermediaryPayloadAlertSettingsArgs = {
  input?: Maybe<IntermediaryAlertSettingsInput>;
};

export type CurrentRangePrice = {
  __typename?: 'CurrentRangePrice';
  /** From, exclusive */
  fromCurrent: Scalars['Int'];
  /** To, inclusive */
  toCurrent: Scalars['Int'];
  /** Price for this current range */
  yearlyPrice: PriceSpecification;
};

export type CustomError = {
  message: Scalars['String'];
  type: ErrorType;
};


export type DateRange = {
  __typename?: 'DateRange';
  /** Day of month and month as a number, including this date */
  from: DayAndMonth;
  /** Day of month and month as a number, excluding this date */
  until: DayAndMonth;
};

export type DayAndMonth = {
  __typename?: 'DayAndMonth';
  day: Scalars['Int'];
  month: Month;
};

/**
 * There may be different prices for day and night, maybe in combination with
 * seasonal (summer/winter) prices. Usually the day is from 6 to 22, but not
 * always.
 */
export type DayAndNightPrices = {
  __typename?: 'DayAndNightPrices';
  day: PricesAndTaxesForPartOfDay;
  night: PricesAndTaxesForPartOfDay;
};

export type DeleteAlertSettingInput = {
  id: Scalars['ID'];
};

export type DeleteAlertSettingPayload = {
  __typename?: 'DeleteAlertSettingPayload';
  success: Scalars['Boolean'];
};

export type DeleteChargingLocationInput = {
  chargingLocationId: Scalars['String'];
};

export type DeleteChargingLocationPayload = {
  __typename?: 'DeleteChargingLocationPayload';
  success: Scalars['Boolean'];
};

export type DeleteJedlixChargingLocationInput = {
  chargingLocationId: Scalars['String'];
};

export type DeleteJedlixChargingLocationPayload = {
  __typename?: 'DeleteJedlixChargingLocationPayload';
  response?: Maybe<Scalars['Void']>;
};

export type DeleteJedlixUserPayload = {
  __typename?: 'DeleteJedlixUserPayload';
  response?: Maybe<Scalars['Void']>;
};

export type DeleteJedlixVehicleInput = {
  vehicleId: Scalars['String'];
};

export type DeleteJedlixVehiclePayload = {
  __typename?: 'DeleteJedlixVehiclePayload';
  response?: Maybe<Scalars['Void']>;
};

export type DeleteMeInput = {
  accessToken: Scalars['String'];
  userId: Scalars['String'];
};

export type DeleteMePayload = {
  __typename?: 'DeleteMePayload';
  success: Scalars['Boolean'];
  user: UserInfo;
};

export type DeliveryChargeCapacityModel = MonthlyPeaksAtDifferentDays | WeightedYearlyRollingPeaksInDifferentWeeks | MonthlyPeaksAtDifferentDaysLinearWithBase | FuseAndVoltageSize;

export type DeliveryChargeEnergyModel = ConstantPrice | DifferentPricesDayAndNight | DifferentPricesSeasonal | DifferentPricesSeasonalDayAndNight | SelectableEnergyModel;

/** Energy taxes (electrical power tax and energy fund tax) */
export type DeliveryChargeEnergyTaxes = {
  __typename?: 'DeliveryChargeEnergyTaxes';
  /** Electrical power tax (Avgift på elektrisk kraft, also called "forbruksavgift") */
  electricalPowerTax: PriceSpecification;
  /** Tax to the energy fund (Enova-avgift) */
  energyFund: PriceSpecification;
};

export type DeliveryChargeModel = {
  __typename?: 'DeliveryChargeModel';
  id: DeliveryChargeModelId;
  capacityModel: DeliveryChargeCapacityModel;
  gln: Scalars['Gln'];
  energyModel: DeliveryChargeEnergyModel;
  taxesApplicable: DeliveryChargeTaxesApplicable;
  timezone: Timezone;
  validFrom: Scalars['Date'];
};

export type DeliveryChargeModelId =
  | 'AGDER_ENERGI_NETT'
  | 'ALUT'
  | 'ANDOY_NETT'
  | 'ARVA'
  | 'ASKER_NETT'
  | 'AUSTEVOLL_KRAFTLAG'
  | 'BARENTS_NETT'
  | 'BINDAL_KRAFTLAG'
  | 'BKK_NETT'
  | 'BOMLO_KRAFTNETT'
  | 'BREHEIM_NETT'
  | 'DE_NETT'
  | 'ELINETT'
  | 'ELMEA'
  | 'ELVENETT'
  | 'ELVIA'
  | 'ELVERKET_HOLAND'
  | 'ENIDA'
  | 'EVERKET'
  | 'FAGNE'
  | 'FJELLNETT'
  | 'FOIE'
  | 'FORE'
  | 'GLITRE_ENERGI_NETT'
  | 'GLITRE_NETT'
  | 'GRIUG'
  | 'HALLINGDAL_KRAFTNETT'
  | 'HALOGALAND_KRAFT_NETT'
  | 'HEMSIL'
  | 'INDRE_HORDALAND_KRAFTNETT'
  | 'ISALTEN_NETT'
  | 'JAREN_EVERK'
  | 'KE_NETT'
  | 'KLIVE'
  | 'KVAM_ENERGI_NETT'
  | 'KYSTNETT'
  | 'LEDE'
  | 'LEGA_NETT'
  | 'LINEA'
  | 'LINJA'
  | 'LNETT'
  | 'LUCERNA'
  | 'LUOSTEJOK_NETT'
  | 'LYSNA'
  | 'MELLOM'
  | 'MELOY_NETT'
  | 'MIDTNETT'
  | 'MODALEN_KRAFTLAG'
  | 'MORENETT'
  | 'NEAS'
  | 'NETTINORD'
  | 'NETTSELSKAPET'
  | 'NORDLANDSNETT'
  | 'NORDVESTNETT'
  | 'NOREFJELL_NETT'
  | 'NORGESNETT'
  | 'ODDA_ENERGI_NETT'
  | 'RAKKESTAD_ENERGI'
  | 'RAUMA_ENERGI'
  | 'RK_NETT'
  | 'ROLLAG_ELVERK_NETT'
  | 'ROMSDALSNETT'
  | 'ROROS_EVERK_NETT'
  | 'S_NETT'
  | 'SANDOY_ENERGI'
  | 'SKIAKER_NETT'
  | 'SKJAK_ENERGI_NETT'
  | 'SOR_AURDAL_ENERGI_NETT'
  | 'SUNETT'
  | 'STANGE_ENERGI_NETT'
  | 'STANNUM'
  | 'STRAUMEN_NETT'
  | 'STRAUMNETT'
  | 'SYGNIR'
  | 'TELEMARK_NETT'
  | 'TENSIO_TN'
  | 'TENSIO_TS'
  | 'TINDRA_NETT'
  | 'TROLLFJORD_NETT'
  | 'TROMS_KRAFT_NETT'
  | 'UVDAL_KRAFTFORSYNING'
  | 'VANG_ENERGI'
  | 'VESTALL'
  | 'VESTMAR_NETT'
  | 'VESTTELEMARK_KRAFTLAG'
  | 'VEVIG'
  | 'VISSI'
  | 'VONETT';

/** Query delivery charge models using `gln` or our `id` */
export type DeliveryChargeModelInput = {
  id?: Maybe<DeliveryChargeModelId>;
  gln?: Maybe<Scalars['Gln']>;
  date?: Maybe<Scalars['Date']>;
};

export type DeliveryChargeTaxesApplicable = {
  __typename?: 'DeliveryChargeTaxesApplicable';
  vat: Scalars['Boolean'];
  electricalPowerTax: Scalars['Boolean'];
};

export type DeviceFilter = {
  deviceIds?: Maybe<Array<Scalars['String']>>;
  recentMeterIds?: Maybe<Array<Scalars['String']>>;
};


export type DeviceIdentifierInput = {
  deviceId?: Maybe<Scalars['DeviceId']>;
  meterId?: Maybe<Scalars['MeterId']>;
};

export type DeviceInfo = {
  __typename?: 'DeviceInfo';
  activation?: Maybe<Activation>;
  deviceId: Scalars['String'];
  type: Scalars['String'];
  claimId: Scalars['String'];
  deviceName?: Maybe<Scalars['String']>;
  intermediaryId?: Maybe<Scalars['String']>;
  recentMeterId?: Maybe<Scalars['MeterId']>;
  recentMeter?: Maybe<RecentMeter>;
  shippedAt?: Maybe<Scalars['String']>;
  shippedTo?: Maybe<ShippedToUserInfo>;
  ownedBy?: Maybe<OwnedByUserInfo>;
  returnInfo?: Maybe<ReturnInfo>;
  claimedAt?: Maybe<Scalars['String']>;
  claimedBy?: Maybe<Scalars['String']>;
  permissions: Array<DevicePermission>;
  diagnostic?: Maybe<DiagnosticInfo>;
  preferredEnergyBiddingZone?: Maybe<EnergyBiddingZone>;
  metadata?: Maybe<DeviceMetadata>;
  subscription?: Maybe<SubscriptionBilling>;
  webhookCallbackUrl?: Maybe<Scalars['String']>;
};

export type DeviceInput = {
  id: Scalars['String'];
  type: DeviceType;
  metadata: DeviceMetadataInput;
};

export type DeviceMetadata = {
  __typename?: 'DeviceMetadata';
  firmwareVersionFactoryPartition?: Maybe<Scalars['String']>;
  espIdfVersionFactoryPartition?: Maybe<Scalars['String']>;
  memoryPartitioning?: Maybe<Scalars['String']>;
  chip?: Maybe<Scalars['String']>;
  productBatch?: Maybe<Scalars['String']>;
  firmwareTheme?: Maybe<Scalars['String']>;
  iccid?: Maybe<Scalars['String']>;
  imei?: Maybe<Scalars['String']>;
  modemFirmwareVersion?: Maybe<Scalars['String']>;
};

export type DeviceMetadataInput = {
  firmwareVersionFactoryPartition: Scalars['String'];
  espIdfVersionFactoryPartition: Scalars['String'];
  memoryPartitioning: Scalars['String'];
  chip: Scalars['String'];
  productBatch: Scalars['String'];
  firmwareTheme: Scalars['String'];
  iccid?: Maybe<Scalars['String']>;
  imei?: Maybe<Scalars['String']>;
  modemFirmwareVersion?: Maybe<Scalars['String']>;
};

/**
 * Input object for updating ownership information
 *
 * Fields will be replaced and missing fields will be removed
 */
export type DeviceOwnerInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  postalAddress?: Maybe<OptionalFieldsPostalAddressInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  externalUserId?: Maybe<Scalars['String']>;
};

export type DevicePermission = {
  __typename?: 'DevicePermission';
  permittedEmail: Scalars['String'];
  permittedUserId: Scalars['String'];
};

export type DeviceQueryIdentifier = {
  deviceId?: Maybe<Scalars['String']>;
  claimId?: Maybe<Scalars['String']>;
  recentMeterId?: Maybe<Scalars['String']>;
};

export type DeviceQueryInput = {
  identifier: DeviceQueryIdentifier;
};

export type DeviceQueryPayload = {
  __typename?: 'DeviceQueryPayload';
  device: DeviceInfo;
};

export type DeviceStats = {
  __typename?: 'DeviceStats';
  total: Scalars['Int'];
  returnedDevices: Scalars['Int'];
  devicesAtCustomer: Scalars['Int'];
  devicesActivated: Scalars['Int'];
  devicesActivationReady: Scalars['Int'];
  devicesDeactivated: Scalars['Int'];
};

export type DeviceType =
  | 'HAN_WIFI'
  | 'HAN_LTEM';

/** Diagnostic about network state and reboots */
export type DiagnosticInfo = {
  __typename?: 'DiagnosticInfo';
  lastMessageAt?: Maybe<Scalars['Date']>;
  lastPingAt?: Maybe<Scalars['Date']>;
  signalStrength?: Maybe<Scalars['Int']>;
  reboots?: Maybe<Scalars['Int']>;
  firmwareVersion?: Maybe<Scalars['Int']>;
  publishInterval?: Maybe<Scalars['Float']>;
};

/**
 * Has different prices for day and night, may also have different prices for
 * weekends and holidays
 */
export type DifferentPricesDayAndNight = {
  __typename?: 'DifferentPricesDayAndNight';
  type: EnergyModelType;
  prices: DayAndNightPrices;
  weekendPricesFollowNightPrices: Scalars['Boolean'];
  holidayPricesFollowNightPrices: Scalars['Boolean'];
};

/** Has different prices for summer and winter */
export type DifferentPricesSeasonal = {
  __typename?: 'DifferentPricesSeasonal';
  type: EnergyModelType;
  seasons: SeasonalPricesWithoutDayAndNight;
};

/**
 * Has different prices for summer and winter, day and night, may also have
 * different prices for weekends and holidays
 */
export type DifferentPricesSeasonalDayAndNight = {
  __typename?: 'DifferentPricesSeasonalDayAndNight';
  type: EnergyModelType;
  seasons: SeasonalPrices;
  weekendPricesFollowNightPrices: Scalars['Boolean'];
  holidayPricesFollowNightPrices: Scalars['Boolean'];
};

export type DisabledSmartChargingStatus = {
  __typename?: 'DisabledSmartChargingStatus';
  type: SmartChargingStatusType;
  externalStart: Scalars['Boolean'];
};

export type DisconnectVendorInput = {
  vendor: IntegrationVendor;
};

export type DisconnectVendorPayload = {
  __typename?: 'DisconnectVendorPayload';
  success: Scalars['Boolean'];
};

export type EmailActionInput = {
  email: Scalars['String'];
  redirectUri?: Maybe<Scalars['String']>;
};

export type EmailIdentity = IdentityBase & {
  __typename?: 'EmailIdentity';
  email: Scalars['String'];
  type: IdentityType;
};

export type EmailIdentityInput = {
  email: Scalars['String'];
};

export type EndUserAlertSettingCreator = {
  __typename?: 'EndUserAlertSettingCreator';
  type: AlertSettingCreatorType;
  userId: Scalars['ID'];
};

/** Energy bidding zones, as reported by the _European Network of Transmission System Operators for Electricity_ (ENTSO-E). */
export type EnergyBiddingZone =
  | 'UNRECOGNIZED'
  /** Norway: south east */
  | 'NO1'
  /** Norway: south */
  | 'NO2'
  /** Norway: mid */
  | 'NO3'
  /** Norway: north */
  | 'NO4'
  /** Norway: west */
  | 'NO5';

export type EnergyModelType =
  | 'ConstantPrice'
  | 'DifferentPricesDayAndNight'
  | 'DifferentPricesSeasonalDayAndNight'
  | 'DifferentPricesSeasonal'
  | 'SelectableEnergyModel';

export type EnergyPriceOfHour = {
  __typename?: 'EnergyPriceOfHour';
  from?: Maybe<Scalars['Date']>;
  to?: Maybe<Scalars['Date']>;
  priceSpecification?: Maybe<PriceSpecification>;
};

/**
 * Energy range is inclusive the from and to fields. A normal range is from 0 to
 * 1999 Wh. The granularity of our data is in Wh.
 */
export type EnergyRangeInWh = {
  __typename?: 'EnergyRangeInWh';
  /** From, inclusive */
  from: Scalars['Int'];
  /** To, inclusive */
  to: Scalars['Int'];
};

export type Error = UserNotPermittedError | UserNotAuthenticatedError;

export type ErrorType =
  | 'UserNotPermittedError'
  | 'UserNotAuthenticatedError';

export type EstimatedHourlyActiveEnergyLimitWarningAlertSetting = {
  __typename?: 'EstimatedHourlyActiveEnergyLimitWarningAlertSetting';
  alertType: AlertType;
  createdBy: AlertSettingCreator;
  deviceId: Scalars['String'];
  id: Scalars['ID'];
  limitInWattHours: Scalars['Int'];
  notificationChannels: Array<NotificationChannel>;
};

export type ExternalUserIdentifier = {
  __typename?: 'ExternalUserIdentifier';
  externalId: Scalars['String'];
};

export type ExternalUserIdentifierInput = {
  externalId: Scalars['String'];
};

export type ExternalUserIdentity = IdentityBase & {
  __typename?: 'ExternalUserIdentity';
  externalId: Scalars['String'];
  intermediaryId: Scalars['String'];
  type: IdentityType;
};

export type FullyChargedSmartChargingStatus = {
  __typename?: 'FullyChargedSmartChargingStatus';
  type: SmartChargingStatusType;
  externalStart: Scalars['Boolean'];
};

/**
 * Capacity model where grid voltage and/or fuse size make the capacity steps.
 *
 * Capacity model used by Alut, NettiNord, SuNett.
 */
export type FuseAndVoltageSize = {
  __typename?: 'FuseAndVoltageSize';
  type: CapacityModelType;
  /** Prices for one phase networks */
  onePhasePrices: PhasePrices;
  /** Prices for three phase networks */
  threePhasePrices: PhasePrices;
};


export type GpsDisabledConfiguredIntegrationVendorVehicleLocationState = {
  __typename?: 'GpsDisabledConfiguredIntegrationVendorVehicleLocationState';
  type: ConfiguredIntegrationVendorVehicleLocationStateType;
};

export type GpsNeverActivatedConfiguredIntegrationVendorVehicleLocationState = {
  __typename?: 'GpsNeverActivatedConfiguredIntegrationVendorVehicleLocationState';
  type: ConfiguredIntegrationVendorVehicleLocationStateType;
};

export type GrantDeviceAccessInput = {
  deviceId: Scalars['String'];
  permittedIdentity: EmailIdentityInput;
};

export type HourRange = {
  __typename?: 'HourRange';
  /** 0-23, including start */
  from: Scalars['Int'];
  /** 0-23, excluding end */
  until: Scalars['Int'];
};

export type HourlyConsumptionLimitEstimationWarningAlertSetting = {
  __typename?: 'HourlyConsumptionLimitEstimationWarningAlertSetting';
  alertType: AlertType;
  createdBy: AlertSettingCreator;
  deviceId: Scalars['String'];
  id: Scalars['ID'];
  limitInWatts: Scalars['Int'];
  notificationChannels: Array<NotificationChannel>;
};

export type Identity = EmailIdentity | ExternalUserIdentity | IntermediaryApiKeyIdentity | InternalIdentity;

export type IdentityBase = {
  type: IdentityType;
};

export type IdentityType =
  | 'EmailIdentity'
  | 'ExternalUserIdentity'
  | 'IntermediaryApiKeyIdentity'
  | 'InternalIdentity';

export type IntegrationAuthorizationUrlInput = {
  vendor: IntegrationVendor;
};

export type IntegrationAuthorizationUrlResponse = {
  __typename?: 'IntegrationAuthorizationUrlResponse';
  authorizationUrl: Scalars['String'];
};

export type IntegrationSessionInput = {
  integration: IntegrationType;
  redirectUrl: Scalars['String'];
};

export type IntegrationSessionPayload = {
  __typename?: 'IntegrationSessionPayload';
  url: Scalars['String'];
};

export type IntegrationType =
  | 'MILL'
  | 'ZAPTEC';

export type IntegrationVendor =
  | 'AUDI'
  | 'BMW'
  | 'CUPRA'
  | 'FORD'
  | 'JAGUAR'
  | 'MINI'
  | 'PORSCHE'
  | 'RENAULT'
  | 'SKODA'
  | 'SEAT'
  | 'VOLVO'
  | 'TESLA'
  | 'VOLKSWAGEN';

export type IntegrationVendorInfo = ConfiguredIntegrationVendorInfo | NotConfiguredIntegrationVendorInfo;

export type IntegrationVendorStatus =
  | 'CONFIGURED'
  | 'NOT_CONFIGURED';

export type IntermediaryAlertSettingCreator = {
  __typename?: 'IntermediaryAlertSettingCreator';
  type: AlertSettingCreatorType;
  intermediaryId: Scalars['String'];
};

export type IntermediaryAlertSettingsFilter = {
  deviceIds?: Maybe<Array<Scalars['String']>>;
};

export type IntermediaryAlertSettingsInput = {
  filter?: Maybe<IntermediaryAlertSettingsFilter>;
};

/** Intermediary api keys are authorized at the intermediate level, to add or remove users on behalf of the intermediary. */
export type IntermediaryApiKey = {
  __typename?: 'IntermediaryApiKey';
  id: Scalars['ID'];
  created: CreationInfo;
  intermediaryId: Scalars['String'];
  name: Scalars['String'];
  startOfSecret: Scalars['String'];
  secret?: Maybe<Scalars['String']>;
  /** @deprecated Use `apiKey` */
  token?: Maybe<Scalars['String']>;
  apiKey?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
};

export type IntermediaryApiKeyIdentity = IdentityBase & {
  __typename?: 'IntermediaryApiKeyIdentity';
  apiKeyHash: Scalars['String'];
  type: IdentityType;
};

export type IntermediaryApiKeyPayload = {
  __typename?: 'IntermediaryApiKeyPayload';
  intermediaryApiKey: IntermediaryApiKey;
};

export type IntermediaryApiKeys = {
  __typename?: 'IntermediaryApiKeys';
  keys: Array<IntermediaryApiKey>;
};

export type IntermediaryApiKeysResult = IntermediaryApiKeys;


/** An intermediary is the entity or organization that has support responsibility for the devices. */
export type IntermediaryInfo = {
  __typename?: 'IntermediaryInfo';
  id: Scalars['ID'];
  devices?: Maybe<Array<DeviceInfo>>;
  apiKeys?: Maybe<IntermediaryApiKeysResult>;
  permissionScopes?: Maybe<Array<Scalars['String']>>;
  deviceStats?: Maybe<DeviceStats>;
};


/** An intermediary is the entity or organization that has support responsibility for the devices. */
export type IntermediaryInfoDevicesArgs = {
  search?: Maybe<Search>;
};

export type IntermediaryUserScope =
  | 'READ_HOURLY_REPORTS'
  | 'READ_BUFFERED_MEASUREMENTS'
  | 'READ_MEASUREMENT_STREAM'
  | 'READ_DEVICE_INFO';

export type IntermediaryUserTokenPayload = {
  __typename?: 'IntermediaryUserTokenPayload';
  accessToken: Scalars['String'];
  user: UserInfo;
};

export type IntermediaryWebhookNotificationChannel = {
  __typename?: 'IntermediaryWebhookNotificationChannel';
  type: NotificationChannelType;
  callbackUrl: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
};

export type IntermediaryWebhookSetting = {
  __typename?: 'IntermediaryWebhookSetting';
  id: Scalars['String'];
};

export type InternalIdentity = IdentityBase & {
  __typename?: 'InternalIdentity';
  id: Scalars['String'];
  type: IdentityType;
};

export type Interval =
  | 'HOURLY'
  | 'DAILY'
  | 'MONTHLY';


export type JedlixActiveNotificationsPayload = {
  __typename?: 'JedlixActiveNotificationsPayload';
  notifications: Array<Maybe<JedlixNotification>>;
};

export type JedlixAddress = {
  __typename?: 'JedlixAddress';
  street?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  houseNumber?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  country: CountryCode;
  priceArea?: Maybe<EnergyBiddingZone>;
};

export type JedlixAddressInput = {
  street?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  houseNumber?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  country: CountryCode;
};

export type JedlixBrand =
  | 'ABARTH'
  | 'AIWAYS'
  | 'AUDI'
  | 'BMW'
  | 'BYD'
  | 'BENTLEY'
  | 'BYTON'
  | 'CUPRA'
  | 'CADILLAC'
  | 'CHEVROLET'
  | 'CITROEN'
  | 'DACIA'
  | 'DR_AUTOMOBILE'
  | 'DS'
  | 'FIAT'
  | 'FISKER'
  | 'FORD'
  | 'GENESIS'
  | 'HONDA'
  | 'HONGQI'
  | 'HYUNDAI'
  | 'JAC'
  | 'JAGUAR'
  | 'JEEP'
  | 'KARMA'
  | 'KIA'
  | 'LEVC'
  | 'LAND_ROVER'
  | 'LEXUS'
  | 'LIGHTYEAR'
  | 'LOTUS'
  | 'LUCID'
  | 'LYNC_AND_CO'
  | 'MG'
  | 'MASERATI'
  | 'MAZDA'
  | 'MERCEDES'
  | 'MINI'
  | 'MITSUBISHI'
  | 'NIO'
  | 'NISSAN'
  | 'OPEL'
  | 'PEUGEOT'
  | 'POLESTAR'
  | 'PORSCHE'
  | 'RENAULT'
  | 'ROLLS_ROYCE'
  | 'SEAT'
  | 'SERES'
  | 'SKODA'
  | 'SMART'
  | 'SONO'
  | 'SSANG_YONG'
  | 'SUBARU'
  | 'SUZUKI'
  | 'TESLA'
  | 'TEST'
  | 'TOYOTA'
  | 'VINFAST'
  | 'VOLKSWAGEN'
  | 'VOLVO'
  | 'XPENG';

export type JedlixChargeState = {
  __typename?: 'JedlixChargeState';
  batteryLevel: Scalars['Float'];
  range: Scalars['Float'];
  chargingStatus: VehicleChargingStatus;
  chargingLocationId: Scalars['String'];
  updatedAt: Scalars['Date'];
  chargePower: Scalars['Float'];
};

export type JedlixChargingHistoryInput = {
  endTimeFrom: Scalars['Date'];
  startTimeTo: Scalars['Date'];
  interval: Scalars['Int'];
  vehicleId: Scalars['String'];
};

export type JedlixChargingHistoryLocation = {
  __typename?: 'JedlixChargingHistoryLocation';
  chargingLocationId: Scalars['String'];
  country: CountryCode;
  priceArea?: Maybe<EnergyBiddingZone>;
};

export type JedlixChargingHistoryPayload = {
  __typename?: 'JedlixChargingHistoryPayload';
  chargingSessionsInInterval: Array<Maybe<ChargingSession>>;
  chargingLocations: Array<Maybe<JedlixChargingHistoryLocation>>;
};

export type JedlixChargingLocation = {
  __typename?: 'JedlixChargingLocation';
  id: Scalars['String'];
  userId: Scalars['String'];
  coordinates: LatLng;
  address: JedlixAddress;
};

export type JedlixChargingLocationInput = {
  chargingLocationId: Scalars['String'];
};

export type JedlixChargingLocationPayload = {
  __typename?: 'JedlixChargingLocationPayload';
  chargingLocation: JedlixChargingLocation;
};

export type JedlixChargingLocationsPayload = {
  __typename?: 'JedlixChargingLocationsPayload';
  chargingLocations: Array<JedlixChargingLocation>;
};

export type JedlixConnection = {
  __typename?: 'JedlixConnection';
  id: Scalars['String'];
  isFinished: Scalars['Boolean'];
  redirectUrl?: Maybe<Scalars['String']>;
  startUrl?: Maybe<Scalars['String']>;
  redirectInfo?: Maybe<JedlixConnectionRedirectInfo>;
};

export type JedlixConnectionRedirectInfo = {
  __typename?: 'JedlixConnectionRedirectInfo';
  includeRedirectUrl?: Maybe<Scalars['Boolean']>;
  includeBody?: Maybe<Scalars['Boolean']>;
  includeCookies: Array<Maybe<Scalars['String']>>;
};

export type JedlixNotification = {
  __typename?: 'JedlixNotification';
  id: Scalars['String'];
  type: MessageType;
  createdAt: Scalars['Date'];
  title: Scalars['String'];
  messageContext: Scalars['String'];
  isDismissable: Scalars['Boolean'];
  dismissedAt?: Maybe<Scalars['Date']>;
  notificationType: JedlixNotificationType;
};

export type JedlixNotificationType =
  | 'PUSH_NOTIFICATION'
  | 'EMAIL'
  | 'SMS'
  | 'BANNER';

export type JedlixUserPayload = {
  __typename?: 'JedlixUserPayload';
  id: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  locale: Scalars['String'];
  chargingLocations?: Maybe<Array<Maybe<JedlixChargingLocation>>>;
};

export type JedlixVehicle = {
  __typename?: 'JedlixVehicle';
  id: Scalars['String'];
  details?: Maybe<VehicleInfo>;
  isConnected: Scalars['Boolean'];
  isConnectable: Scalars['Boolean'];
  capabilities: VehicleCapabilities;
  chargeState?: Maybe<JedlixChargeState>;
};

export type LatLng = {
  __typename?: 'LatLng';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type LatLngInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type ListJedlixVehiclesPayload = {
  __typename?: 'ListJedlixVehiclesPayload';
  vehicles: Array<Maybe<JedlixVehicle>>;
};

export type Location = {
  __typename?: 'Location';
  id: Scalars['String'];
  name: Scalars['String'];
  controllableIds: Array<Maybe<Scalars['String']>>;
  dso?: Maybe<DeliveryChargeModelId>;
  coordinates: LatLng;
  deviceId?: Maybe<Scalars['String']>;
  hourlyEnergyConsumptionLimit?: Maybe<Scalars['Float']>;
};

export type LocationAvailableConfiguredIntegrationVendorVehicleLocationState = {
  __typename?: 'LocationAvailableConfiguredIntegrationVendorVehicleLocationState';
  type: ConfiguredIntegrationVendorVehicleLocationStateType;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  lastUpdatedAt: Scalars['String'];
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type LoginPayload = {
  __typename?: 'LoginPayload';
  session: SessionInfo;
  user: UserInfo;
};

export type Measurement = {
  __typename?: 'Measurement';
  /** Active power import, in W */
  activePowerImport?: Maybe<Scalars['Float']>;
  /** Reactive power import, in var */
  reactivePowerImport?: Maybe<Scalars['Float']>;
  /** Active energy imported, in Wh */
  activeEnergyImported?: Maybe<Scalars['Float']>;
  /** Reactive energy imported, in varh */
  reactiveEnergyImported?: Maybe<Scalars['Float']>;
  /** Active power export, in W */
  activePowerExport?: Maybe<Scalars['Float']>;
  /** Reactive power export, in var */
  reactivePowerExport?: Maybe<Scalars['Float']>;
  /** Active energy exported, in Wh */
  activeEnergyExported?: Maybe<Scalars['Float']>;
  /** Reactive energy exported, in varh */
  reactiveEnergyExported?: Maybe<Scalars['Float']>;
  /** Voltage, phase 1, in V */
  voltagePhase1?: Maybe<Scalars['Float']>;
  /** Voltage, phase 2, in V */
  voltagePhase2?: Maybe<Scalars['Float']>;
  /** Voltage, phase 3, in V */
  voltagePhase3?: Maybe<Scalars['Float']>;
  /** Current, phase 1, in A */
  currentPhase1?: Maybe<Scalars['Float']>;
  /** Current, phase 2, in A */
  currentPhase2?: Maybe<Scalars['Float']>;
  /** Current, phase 3, in A */
  currentPhase3?: Maybe<Scalars['Float']>;
  /** Time in UTC */
  time: Scalars['Date'];
};

export type MessageType =
  | 'START_SESSION'
  | 'END_SESSION'
  | 'INVALID_CREDENTIALS'
  | 'CAR_NOT_FOUND'
  | 'CAR_NOT_REACHABLE'
  | 'ZE_INTERACTIVE_TO_BE_ACTIVATED'
  | 'ZE_INTERACTIVE_EXPIRES_SOON'
  | 'READY_FOR_PAIRING'
  | 'SESSION_IMPOSSIBLE'
  | 'CHECK_CAR_ACTIVATION'
  | 'RECEIVED_NO_POWER'
  | 'GEO_LOCATION_DISABLED'
  | 'AUDI_NOT_PAIRED'
  | 'AUDI_PAIRING_NOT_COMPLETE'
  | 'INVALID_UTILITY_CONTRACT'
  | 'RECONNECT_UTILITY_CONTRACT'
  | 'INVALID_CREDENTIALS_BANNER'
  | 'MY_RENAULT_ACCESS_REVOKED'
  | 'AUDI_REMOTE_ACCESS_DISABLED'
  | 'AUDI_REMOTE_ACCRESS_DISABLED_BANNER';

/** Metadata about our services */
export type Metadata = {
  __typename?: 'Metadata';
  /** Required version to run the app. The app should block the user from using an older version than this. */
  requiredAppVersion: Scalars['String'];
  /** Suggested version to run the app. The app will present the user with information about expecting errors when running older versions of the app. */
  suggestedAppVersion: Scalars['String'];
};


export type MeterProducer =
  | 'UNRECOGNIZED'
  | 'AIDON'
  | 'KAIFA'
  | 'KAMSTRUP'
  | 'EMBRIQ';

export type MillSignInInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MillSignInPayload = {
  __typename?: 'MillSignInPayload';
  success: Scalars['Boolean'];
};

export type Month =
  | 'JANUARY'
  | 'FEBRUARY'
  | 'MARCH'
  | 'APRIL'
  | 'MAY'
  | 'JUNE'
  | 'JULY'
  | 'AUGUST'
  | 'SEPTEMBER'
  | 'OCTOBER'
  | 'NOVEMBER'
  | 'DECEMBER';

export type MonthRange = {
  __typename?: 'MonthRange';
  /** 1-12, including start */
  from: Scalars['Int'];
  /** 1-12, exluding end */
  until: Scalars['Int'];
};

export type MonthWeights = {
  __typename?: 'MonthWeights';
  january: Scalars['Float'];
  february: Scalars['Float'];
  march: Scalars['Float'];
  april: Scalars['Float'];
  may: Scalars['Float'];
  june: Scalars['Float'];
  july: Scalars['Float'];
  august: Scalars['Float'];
  september: Scalars['Float'];
  october: Scalars['Float'];
  november: Scalars['Float'];
  december: Scalars['Float'];
};

/**
 * Capacity model where (e.g. 3) daily maximas for a month is averaged and used to
 * determine the capacity step. The steps has a range in Wh with a price, and
 * there is also a field defining the price for when all capacity steps are
 * exceeded.
 */
export type MonthlyPeaksAtDifferentDays = {
  __typename?: 'MonthlyPeaksAtDifferentDays';
  type: CapacityModelType;
  /**
   * The number of daily peaks that will be averaged to find the capacity step.
   * This is normally three, but there are a few cases using 1.
   */
  peaksPerMonth: Scalars['Int'];
  capacitySteps: Array<CapacityStep>;
  /**
   * Normally, there is an upper limit, above which there is a costly price.
   * Sometimes, the grid companies references a list of commercial capacity steps
   * when the consumtion is high, but we assume the customers are below the extra
   * commercial steps and "wrongly" set the highest of the private capacity step
   * prices, for these few cases.
   */
  priceAboveCapacitySteps: PriceSpecification;
};

/**
 * Capacity model where (e.g. 3) daily maximas for a month is averaged and
 * multiplied by `pricePerKwhForAverageOfPeaks` and added to `baseFeePerYear`.
 *
 * Capacity model used by SkiakerNett, but with only one maxima.
 *
 * <https://skiakernett.no/nettleige/>
 */
export type MonthlyPeaksAtDifferentDaysLinearWithBase = {
  __typename?: 'MonthlyPeaksAtDifferentDaysLinearWithBase';
  type: CapacityModelType;
  baseFeePerYear: PriceSpecification;
  /** The number of daily peaks that will be averaged to find the capacity step. */
  peaksPerMonth: Scalars['Int'];
  pricePerKwhForAverageOfPeaks: PriceSpecification;
};

export type Mutation = {
  __typename?: 'Mutation';
  login: LoginPayload;
  register?: Maybe<Scalars['Boolean']>;
  sendResetPasswordEmail?: Maybe<Scalars['Boolean']>;
  sendEmailVerificationEmail?: Maybe<Scalars['Boolean']>;
  claimDevice: DeviceInfo;
  createDevice: DeviceInfo;
  shipDevice: DeviceInfo;
  grantDeviceAccess: Permission;
  revokeDeviceAccess?: Maybe<Scalars['Boolean']>;
  /**
   * Updates the device name
   *
   * Since the `deviceName` input is mandatory, there is another mutation to delete the device name.
   */
  updateDeviceName?: Maybe<DeviceInfo>;
  deleteDeviceName?: Maybe<DeviceInfo>;
  /**
   * Update device owner information. Will not change the info about who the device was shipped to.
   *
   * `deviceOwner` will be replaced by the new input object, and missing fields will be removed.
   */
  updateDeviceOwner?: Maybe<DeviceInfo>;
  updatePreferredEnergyBiddingZone?: Maybe<Scalars['Boolean']>;
  addDeviceToken?: Maybe<Scalars['Void']>;
  removeDeviceToken?: Maybe<Scalars['Void']>;
  addPriceWarningAlertSetting: PriceWarningAlertSetting;
  addHourlyConsumptionLimitEstimationWarningAlertSetting: HourlyConsumptionLimitEstimationWarningAlertSetting;
  updateHourlyConsumptionLimitEstimationWarningAlertSetting: UpdateHourlyConsumptionLimitEstimationWarningAlertSettingPayload;
  addEstimatedHourlyActiveEnergyLimitWarningAlertSetting: EstimatedHourlyActiveEnergyLimitWarningAlertSetting;
  updateEstimatedHourlyActiveEnergyLimitWarningAlertSetting: UpdateEstimatedHourlyActiveEnergyLimitWarningAlertSettingPayload;
  deleteAlertSetting: DeleteAlertSettingPayload;
  addPushNotificationChannelToAlertSetting: AddPushNotificationChannelToAlertSettingPayload;
  removeNotificationChannelFromAlertSetting: RemoveNotificationChannelFromAlertSettingPayload;
  addIntermediaryWebhookNotificationChannelToAlertSetting: AddIntermediaryWebhookNotificationChannelToAlertSettingPayload;
  setIntermediaryWebhookSetting: SetIntermediaryWebhookSettingPayload;
  addIntermediaryApiKey: IntermediaryApiKeyPayload;
  removeIntermediaryApiKey: IntermediaryApiKeyPayload;
  addChargingLocation: AddChargingLocationPayload;
  disconnectVendor: DisconnectVendorPayload;
  updateSmartChargingPolicy: UpdateSmartChargingPolicyPayload;
  addUser: AddUserPayload;
  removeUser: RemoveUserPayload;
  connectExternalUser: ConnectExternalUserPayload;
  deleteMe: DeleteMePayload;
  addUserSession: AddUserSessionPayload;
  addDevicePermissions?: Maybe<AddDevicePermissionsPayload>;
  removeDevicePermissions?: Maybe<RemoveDevicePermissionsPayload>;
  addChargingPermission?: Maybe<AddChargingPermissionPayload>;
  removeChargingPermission?: Maybe<RemoveChargingPermissionPayload>;
  updateChargingLocation: UpdateChargingLocationPayload;
  deleteChargingLocation: DeleteChargingLocationPayload;
  testWebhookSetting: TestWebhookSettingPayload;
  returnDevice: ReturnDevicePayload;
  setDeviceActivationStatus: SetDeviceActivationStatusPayload;
  orderDevice: OrderDevicePayload;
  cancelOrder: Order;
  processOrderForIntermediary?: Maybe<ProcessOrderForIntermediaryPayload>;
  shipOrder: Order;
  connectJedlixVehicle: JedlixConnection;
  createJedlixUser: JedlixUserPayload;
  zaptecClaimInstallation: ZaptecClaimInstallationPayload;
  startIntegrationSession: IntegrationSessionPayload;
  createJedlixChargingLocation: JedlixChargingLocation;
  updateJedlixChargingLocation: JedlixChargingLocation;
  deleteJedlixChargingLocation?: Maybe<DeleteJedlixChargingLocationPayload>;
  deleteJedlixUser?: Maybe<DeleteJedlixUserPayload>;
  deleteJedlixVehicle?: Maybe<DeleteJedlixVehiclePayload>;
  millSignIn?: Maybe<MillSignInPayload>;
  addLocation: AddLocationPayload;
  removeLocation: RemoveLocationPayload;
  setCoordinatesForLocation: SetCoordinatesForLocationPayload;
  setDeviceForLocation: SetDeviceForLocationPayload;
  setNameForLocation: SetNameForLocationPayload;
  addControllableForLocation: AddControllableForLocationPayload;
  commonSignIn: CommonSignInPayload;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationRegisterArgs = {
  input: RegisterInput;
};


export type MutationSendResetPasswordEmailArgs = {
  input: EmailActionInput;
};


export type MutationSendEmailVerificationEmailArgs = {
  input: EmailActionInput;
};


export type MutationClaimDeviceArgs = {
  claimId: Scalars['ClaimId'];
};


export type MutationCreateDeviceArgs = {
  input: DeviceInput;
};


export type MutationShipDeviceArgs = {
  deviceIdentifier: ShipDeviceIdentifierInput;
  intermediaryId: Scalars['String'];
  shipTo?: Maybe<ShipToUserInput>;
  subscription: SubscriptionInput;
  webhookCallbackUrl?: Maybe<Scalars['String']>;
};


export type MutationGrantDeviceAccessArgs = {
  input: GrantDeviceAccessInput;
};


export type MutationRevokeDeviceAccessArgs = {
  input: RevokeDeviceAccessInput;
};


export type MutationUpdateDeviceNameArgs = {
  deviceId: Scalars['String'];
  deviceName: Scalars['String'];
};


export type MutationDeleteDeviceNameArgs = {
  deviceId: Scalars['String'];
};


export type MutationUpdateDeviceOwnerArgs = {
  deviceId: Scalars['String'];
  deviceOwner: DeviceOwnerInput;
};


export type MutationUpdatePreferredEnergyBiddingZoneArgs = {
  deviceId: Scalars['String'];
  preferredEnergyBiddingZone: EnergyBiddingZone;
};


export type MutationAddDeviceTokenArgs = {
  deviceToken: Scalars['String'];
};


export type MutationRemoveDeviceTokenArgs = {
  deviceToken: Scalars['String'];
};


export type MutationAddPriceWarningAlertSettingArgs = {
  input: AddPriceWarningAlertSettingInput;
};


export type MutationAddHourlyConsumptionLimitEstimationWarningAlertSettingArgs = {
  input: AddHourlyConsumptionLimitEstimationWarningAlertSettingInput;
};


export type MutationUpdateHourlyConsumptionLimitEstimationWarningAlertSettingArgs = {
  input: UpdateHourlyConsumptionLimitEstimationWarningAlertSettingInput;
};


export type MutationAddEstimatedHourlyActiveEnergyLimitWarningAlertSettingArgs = {
  input: AddEstimatedHourlyActiveEnergyLimitWarningAlertSettingInput;
};


export type MutationUpdateEstimatedHourlyActiveEnergyLimitWarningAlertSettingArgs = {
  input: UpdateEstimatedHourlyActiveEnergyLimitWarningAlertSettingInput;
};


export type MutationDeleteAlertSettingArgs = {
  input: DeleteAlertSettingInput;
};


export type MutationAddPushNotificationChannelToAlertSettingArgs = {
  input: AddPushNotificationChannelToAlertSettingInput;
};


export type MutationRemoveNotificationChannelFromAlertSettingArgs = {
  input: RemoveNotificationChannelFromAlertSettingInput;
};


export type MutationAddIntermediaryWebhookNotificationChannelToAlertSettingArgs = {
  input: AddIntermediaryWebhookNotificationChannelToAlertSettingInput;
};


export type MutationSetIntermediaryWebhookSettingArgs = {
  input: SetIntermediaryWebhookSettingInput;
};


export type MutationAddIntermediaryApiKeyArgs = {
  input: AddIntermediaryApiKeyInput;
};


export type MutationRemoveIntermediaryApiKeyArgs = {
  input: RemoveIntermediaryApiKeyInput;
};


export type MutationAddChargingLocationArgs = {
  input: AddChargingLocationInput;
};


export type MutationDisconnectVendorArgs = {
  input: DisconnectVendorInput;
};


export type MutationUpdateSmartChargingPolicyArgs = {
  input: UpdateSmartChargingPolicyInput;
};


export type MutationAddUserArgs = {
  input: AddUserInput;
};


export type MutationRemoveUserArgs = {
  input: RemoveUserInput;
};


export type MutationConnectExternalUserArgs = {
  input: ConnectExternalUserInput;
};


export type MutationDeleteMeArgs = {
  input: DeleteMeInput;
};


export type MutationAddUserSessionArgs = {
  input: AddUserSessionInput;
};


export type MutationAddDevicePermissionsArgs = {
  input: AddDevicePermissionsInput;
};


export type MutationRemoveDevicePermissionsArgs = {
  input: RemoveDevicePermissionsInput;
};


export type MutationAddChargingPermissionArgs = {
  input: AddChargingPermissionInput;
};


export type MutationRemoveChargingPermissionArgs = {
  input: RemoveChargingPermissionInput;
};


export type MutationUpdateChargingLocationArgs = {
  input: UpdateChargingLocationInput;
};


export type MutationDeleteChargingLocationArgs = {
  input: DeleteChargingLocationInput;
};


export type MutationTestWebhookSettingArgs = {
  input: TestWebhookSettingInput;
};


export type MutationReturnDeviceArgs = {
  input: ReturnDeviceInput;
};


export type MutationSetDeviceActivationStatusArgs = {
  input: SetDeviceActivationStatusInput;
};


export type MutationOrderDeviceArgs = {
  input: OrderDeviceInput;
};


export type MutationCancelOrderArgs = {
  input: CancelOrderInput;
};


export type MutationProcessOrderForIntermediaryArgs = {
  input: ProcessOrderForIntermediaryInput;
};


export type MutationShipOrderArgs = {
  input: ShipOrderInput;
};


export type MutationConnectJedlixVehicleArgs = {
  input?: Maybe<Scalars['Void']>;
};


export type MutationCreateJedlixUserArgs = {
  input: CreateJedlixUserInput;
};


export type MutationZaptecClaimInstallationArgs = {
  input: ZaptecClaimInstallationInput;
};


export type MutationStartIntegrationSessionArgs = {
  input: IntegrationSessionInput;
};


export type MutationCreateJedlixChargingLocationArgs = {
  input: CreateJedlixChargingLocationInput;
};


export type MutationUpdateJedlixChargingLocationArgs = {
  input: UpdateJedlixChargingLocationInput;
};


export type MutationDeleteJedlixChargingLocationArgs = {
  input: DeleteJedlixChargingLocationInput;
};


export type MutationDeleteJedlixUserArgs = {
  input: Scalars['Void'];
};


export type MutationDeleteJedlixVehicleArgs = {
  input: DeleteJedlixVehicleInput;
};


export type MutationMillSignInArgs = {
  input: MillSignInInput;
};


export type MutationAddLocationArgs = {
  input: AddLocationInput;
};


export type MutationRemoveLocationArgs = {
  input: RemoveLocationInput;
};


export type MutationSetCoordinatesForLocationArgs = {
  input: SetCoordinatesForLocationInput;
};


export type MutationSetDeviceForLocationArgs = {
  input: SetDeviceForLocationInput;
};


export type MutationSetNameForLocationArgs = {
  input: SetNameForLocationInput;
};


export type MutationAddControllableForLocationArgs = {
  input: AddControllableForLocationInput;
};


export type MutationCommonSignInArgs = {
  input: CommonSignInInput;
};

export type MyDevicesInput = {
  filter?: Maybe<DeviceFilter>;
};

export type NotConfiguredIntegrationVendorInfo = {
  __typename?: 'NotConfiguredIntegrationVendorInfo';
  status: IntegrationVendorStatus;
  vendor: IntegrationVendor;
};

export type NotificationChannel = IntermediaryWebhookNotificationChannel | PushNotificationChannel;

export type NotificationChannelType =
  | 'PUSH_NOTIFICATION'
  | 'INTERMEDIARY_WEBHOOK';

export type NotificationType =
  | 'PRICE_WARNING';

export type OptionalFieldsPostalAddress = {
  __typename?: 'OptionalFieldsPostalAddress';
  streetAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  country?: Maybe<PostcodeCountry>;
};

export type OptionalFieldsPostalAddressInput = {
  streetAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  country?: Maybe<PostcodeCountry>;
};

export type Order = {
  __typename?: 'Order';
  intermediaryId: Scalars['String'];
  orderId: Scalars['String'];
  orderStatus: OrderStatus;
  recipient: PostalRecipient;
  subscriptionType: OrderSubscriptionType;
  type: DeviceType;
  updatedAt: Scalars['Date'];
  deviceId?: Maybe<Scalars['String']>;
  trackingLink?: Maybe<Scalars['String']>;
  webhookCallbackUrl?: Maybe<Scalars['String']>;
};

export type OrderDeviceInput = {
  recipient: PostalRecipientInput;
  subscriptionType: OrderSubscriptionType;
  type: DeviceType;
  webhookCallbackUrl?: Maybe<Scalars['String']>;
};

export type OrderDevicePayload = {
  __typename?: 'OrderDevicePayload';
  order: Order;
  ordersForUser: Array<Maybe<Order>>;
};

export type OrderInput = {
  orderId: Scalars['String'];
};

export type OrderStatus =
  | 'ORDERED'
  | 'SHIPPED'
  | 'CANCELLED'
  | 'IN_PROGRESS';

export type OrderSubscriptionType =
  | 'PERSONAL'
  | 'BUSINESS';

export type OrderSummary = {
  __typename?: 'OrderSummary';
  intermediaryId: Scalars['String'];
  ltemsToBeShipped: Scalars['Int'];
  wifisToBeShipped: Scalars['Int'];
};

export type OrderSummaryPayload = {
  __typename?: 'OrderSummaryPayload';
  orderSummary: Array<Maybe<OrderSummary>>;
};

export type OrdersForUserInput = {
  externalUserId: Scalars['String'];
};

export type OrdersForUserPayload = {
  __typename?: 'OrdersForUserPayload';
  orders: Array<Order>;
};

/** User information for device owners */
export type OwnedByUserInfo = {
  __typename?: 'OwnedByUserInfo';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  postalAddress?: Maybe<OptionalFieldsPostalAddress>;
  phoneNumber?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  externalUserId?: Maybe<Scalars['String']>;
};

/** Our permission system works across different types of resources and different types of actors */
export type Permission = {
  __typename?: 'Permission';
  /** The id of the resource the permission is targeting, e.g. a deviceId */
  resourceId: Scalars['String'];
  /** The id of the entity being permitted, e.g. a userId */
  entityId: Scalars['String'];
  /** Permissions to resources, like read or write, are defined by scopes */
  scopes: Array<Scalars['String']>;
};

export type PhasePrices = {
  __typename?: 'PhasePrices';
  /** Prices for 230V networks */
  voltage230: PhaseVoltagePrices;
  /** Prices for 400V networks */
  voltage400: PhaseVoltagePrices;
};

export type PhaseVoltagePrices = {
  __typename?: 'PhaseVoltagePrices';
  currentRangePrices: Array<CurrentRangePrice>;
  yearlyPriceAboveCurrentRanges: PriceSpecification;
};

export type PlannedSmartChargingStatus = {
  __typename?: 'PlannedSmartChargingStatus';
  type: SmartChargingStatusType;
  plan: SmartChargingPlan;
  planningStatus: SmartChargingPlanningStatusType;
  externalStart: Scalars['Boolean'];
};

export type PostalAddress = {
  __typename?: 'PostalAddress';
  streetAddress: Scalars['String'];
  postcode: Scalars['String'];
  country: PostcodeCountry;
};

export type PostalAddressInput = {
  streetAddress: Scalars['String'];
  postcode: Scalars['String'];
  country: PostcodeCountry;
};

export type PostalRecipient = {
  __typename?: 'PostalRecipient';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  externalUserId: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  postalAddress: PostalRecipientPostalAddress;
};

export type PostalRecipientInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  externalUserId: Scalars['String'];
  /** Max-length 60 characters */
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  postalAddress: PostalRecipientPostalAddressInput;
};

export type PostalRecipientPostalAddress = {
  __typename?: 'PostalRecipientPostalAddress';
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  postalCode: Scalars['String'];
  country: CountryCode;
};

export type PostalRecipientPostalAddressInput = {
  /** Max-length 35 characters */
  addressLine1: Scalars['String'];
  /** Max-length 35 characters */
  addressLine2?: Maybe<Scalars['String']>;
  /** Max-length 35 characters */
  city: Scalars['String'];
  /** 4 digits if country is Norway */
  postalCode: Scalars['String'];
  country: CountryCode;
};

export type PostcodeCountry =
  | 'NORWAY';

export type PriceSpecification = {
  __typename?: 'PriceSpecification';
  /** The actual price, this should be including VAT (MVA) where it applies */
  price: Scalars['Float'];
  /** The currency of the given price, usually in NOK */
  priceCurrency: Currency;
  /** VAT, or "Merverdiavgift" */
  vat: Scalars['Float'];
  /** The prices without VAT (MVA) */
  priceExcludingVat: Scalars['Float'];
};

export type PriceWarningAlertSetting = {
  __typename?: 'PriceWarningAlertSetting';
  alertType: AlertType;
  energyBiddingZone: EnergyBiddingZone;
  createdBy: AlertSettingCreator;
  id: Scalars['ID'];
  notificationChannels: Array<NotificationChannel>;
};

export type PriceWarningPushNotificationSetting = {
  __typename?: 'PriceWarningPushNotificationSetting';
  notificationType: NotificationType;
  wantsNotification: Scalars['Boolean'];
  deviceId: Scalars['String'];
};

export type PricesAndTaxes = {
  __typename?: 'PricesAndTaxes';
  priceIncludingEnergyTaxes: PriceSpecification;
  priceExcludingEnergyTaxes: PriceSpecification;
  taxes: DeliveryChargeEnergyTaxes;
};

/**
 * Price and tax info for part of a day, includes a specification of which hours
 * the price is for.
 */
export type PricesAndTaxesForPartOfDay = {
  __typename?: 'PricesAndTaxesForPartOfDay';
  hours: HourRange;
  pricesAndTaxes: PricesAndTaxes;
};

/**
 * The prices for a part of year, will include the price specifiations and a
 * for what months the prices is for
 */
export type PricesForPartOfYear = {
  __typename?: 'PricesForPartOfYear';
  months: MonthRange;
  prices: DayAndNightPrices;
};

/**
 * The prices for a part of year without differentiating day and night, will include the price specifiations and a
 * for what months the prices is for
 */
export type PricesForPartOfYearWithoutDayAndNight = {
  __typename?: 'PricesForPartOfYearWithoutDayAndNight';
  months: MonthRange;
  /** @deprecated The field is not complete, use the adjacent field `pricesAndTaxes` */
  price: PriceSpecification;
  pricesAndTaxes: PricesAndTaxes;
};

export type ProcessOrderForIntermediaryInput = {
  intermediaryId: Scalars['String'];
  deviceType: DeviceType;
};

export type ProcessOrderForIntermediaryPayload = {
  __typename?: 'ProcessOrderForIntermediaryPayload';
  order?: Maybe<Order>;
};

export type PushNotificationChannel = {
  __typename?: 'PushNotificationChannel';
  type: NotificationChannelType;
  forUserWithId: Scalars['String'];
};

export type PushNotificationSetting = PriceWarningPushNotificationSetting;

export type Query = {
  __typename?: 'Query';
  /** Get permissions containing requested scopes for the authenticated user */
  permissions: Array<Permission>;
  /**
   * Get hourly or daily reports using deviceId or meterId (or both)
   *
   * * If only deviceId is given, reports for the most recent meterId that the device was connected to in the requested range will be returned
   * * If only meterId is given, reports for this meter will be returned from the most recent deviceId that was connected to this meter in the requested range
   * * If both meterId and deviceId is given, reports will only be returned if the given deviceId was connected to the given meterId in the requested range
   *
   * In all cases the accessToken needs permissions to read reports for the corresponding deviceId.
   */
  reports: Array<Report>;
  /**
   * Get data from the latest measurements using deviceId or meterId (or both)
   *
   * * If only deviceId is given, reports for the most recent meterId that the device was connected to in the requested range will be returned
   * * If only meterId is given, reports for this meter will be returned from the most recent deviceId that was connected to this meter in the requested range
   * * If both meterId and deviceId is given, reports will only be returned if the given deviceId was connected to the given meterId in the requested range
   *
   * In all cases the accessToken needs permissions to read reports for the corresponding deviceId.
   *
   * Measurements are only available for two to three hours.
   */
  measurements: Array<Measurement>;
  /** Get metadata about services, like minimum required app requiredAppVersion */
  metadata: Metadata;
  myDevices: Array<DeviceInfo>;
  me: UserInfo;
  currentIntermediary: CurrentIntermediaryPayload;
  locations: Array<Location>;
  /**
   * Day ahead prices
   *
   * Get price data from the Entso-E transparency platrom for the time range
   * specified.
   *
   * Prices are converted to NOK and VAT is added for the zones where
   * this is applicable.
   *
   * Read more about the transparency platform of the European Network of
   * Transmission System Operators at https://transparency.entsoe.eu/
   */
  dayAheadPrices: Array<Maybe<EnergyPriceOfHour>>;
  /** Generates an authorization URL which can be embedded in a webview which in turn will allow an end user to grant our API access to control their vehicle/charger */
  integrationAuthorizationUrl: IntegrationAuthorizationUrlResponse;
  user: UserPayload;
  vehicleSmartChargingStatus: VehicleSmartChargingStatusPayload;
  chargingStatistics: ChargingStatisticsPayload;
  device: DeviceQueryPayload;
  deliveryChargeModel: DeliveryChargeModel;
  order: Order;
  ordersForUser: OrdersForUserPayload;
  orderSummary: OrderSummaryPayload;
  checkJedlixConnection: JedlixConnection;
  jedlixActiveNotifications: JedlixActiveNotificationsPayload;
  jedlixChargingHistory: JedlixChargingHistoryPayload;
  listJedlixVehicles: ListJedlixVehiclesPayload;
  jedlixChargingLocation: JedlixChargingLocationPayload;
  jedlixChargingLocations: JedlixChargingLocationsPayload;
  jedlixUser: JedlixUserPayload;
  getControllablesForUser: ControllablesForUserPayload;
};


export type QueryPermissionsArgs = {
  scopes: Array<Scalars['String']>;
};


export type QueryReportsArgs = {
  deviceId?: Maybe<Scalars['String']>;
  meterId?: Maybe<Scalars['String']>;
  interval: Interval;
  from: Scalars['String'];
  to: Scalars['String'];
  timezone?: Maybe<Timezone>;
};


export type QueryMeasurementsArgs = {
  deviceId?: Maybe<Scalars['String']>;
  meterId?: Maybe<Scalars['String']>;
  from: Scalars['String'];
  to: Scalars['String'];
};


export type QueryMyDevicesArgs = {
  input?: Maybe<MyDevicesInput>;
};


export type QueryDayAheadPricesArgs = {
  from: Scalars['Date'];
  to: Scalars['Date'];
  zone: EnergyBiddingZone;
};


export type QueryIntegrationAuthorizationUrlArgs = {
  input: IntegrationAuthorizationUrlInput;
};


export type QueryUserArgs = {
  input: UserInput;
};


export type QueryVehicleSmartChargingStatusArgs = {
  input: VehicleSmartChargingStatusInput;
};


export type QueryChargingStatisticsArgs = {
  input: ChargingStatisticsInput;
};


export type QueryDeviceArgs = {
  input: DeviceQueryInput;
};


export type QueryDeliveryChargeModelArgs = {
  input: DeliveryChargeModelInput;
};


export type QueryOrderArgs = {
  input: OrderInput;
};


export type QueryOrdersForUserArgs = {
  input: OrdersForUserInput;
};


export type QueryOrderSummaryArgs = {
  input?: Maybe<Scalars['Void']>;
};


export type QueryCheckJedlixConnectionArgs = {
  input: CheckJedlixConnectionInput;
};


export type QueryJedlixActiveNotificationsArgs = {
  input?: Maybe<Scalars['Void']>;
};


export type QueryJedlixChargingHistoryArgs = {
  input: JedlixChargingHistoryInput;
};


export type QueryListJedlixVehiclesArgs = {
  input?: Maybe<Scalars['Void']>;
};


export type QueryJedlixChargingLocationArgs = {
  input: JedlixChargingLocationInput;
};


export type QueryJedlixChargingLocationsArgs = {
  input?: Maybe<Scalars['Void']>;
};


export type QueryJedlixUserArgs = {
  input: Scalars['Void'];
};


export type QueryGetControllablesForUserArgs = {
  input?: Maybe<Scalars['Void']>;
};

export type Range = {
  __typename?: 'Range';
  max: Scalars['Float'];
  min: Scalars['Float'];
  mean: Scalars['Float'];
};

export type RecentMeter = {
  __typename?: 'RecentMeter';
  meterId: Scalars['MeterId'];
  producer: MeterProducer;
  shortMeterId: Scalars['String'];
  firstSeenAt: Scalars['Date'];
};

export type RegisterInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  redirectUri?: Maybe<Scalars['String']>;
};

export type RemoveChargingPermissionInput = {
  userIdentifier: UserIdentifierInput;
};

export type RemoveChargingPermissionPayload = {
  __typename?: 'RemoveChargingPermissionPayload';
  modifiedPermission: Permission;
  success: Scalars['Boolean'];
  user: UserInfo;
};

export type RemoveDevicePermissionsInput = {
  deviceIdentifiers: Array<DeviceIdentifierInput>;
  userIdentifier: UserIdentifierInput;
};

export type RemoveDevicePermissionsPayload = {
  __typename?: 'RemoveDevicePermissionsPayload';
  modifiedPermissions: Array<Permission>;
  success: Scalars['Boolean'];
  user: UserInfo;
};

export type RemoveIntermediaryApiKeyInput = {
  id: Scalars['String'];
};

export type RemoveIntermediaryUserInput = {
  externalId: Scalars['String'];
};

export type RemoveIntermediaryUserPayload = {
  __typename?: 'RemoveIntermediaryUserPayload';
  user: UserInfo;
};

export type RemoveLocationInput = {
  locationId: Scalars['String'];
};

export type RemoveLocationPayload = {
  __typename?: 'RemoveLocationPayload';
  location: Location;
};

export type RemoveNotificationChannelFromAlertSettingInput = {
  alertSettingId: Scalars['ID'];
  notificationChannelType: NotificationChannelType;
};

export type RemoveNotificationChannelFromAlertSettingPayload = {
  __typename?: 'RemoveNotificationChannelFromAlertSettingPayload';
  success: Scalars['Boolean'];
};

export type RemoveUserInput = {
  userIdentifier: UserIdentifierInput;
};

export type RemoveUserPayload = {
  __typename?: 'RemoveUserPayload';
  success: Scalars['Boolean'];
  user: UserInfo;
};

export type Report = {
  __typename?: 'Report';
  /** Average of ActivePowerImport values in the time period, in W */
  averageActivePowerImport?: Maybe<Scalars['Float']>;
  /**
   * Active energy imported in the time period, in Wh
   *
   * Not available for the _current hour_
   */
  activeEnergyImported?: Maybe<Scalars['Float']>;
  /** Average of ActivePowerExport values in the time period, in W */
  averageActivePowerExport?: Maybe<Scalars['Float']>;
  /**
   * Active energy exported in the time period, in Wh
   *
   * Not available for the _current hour_
   */
  activeEnergyExported?: Maybe<Scalars['Float']>;
  /** Cumulative values of the active energy import in Wh at the start of the interval */
  cumulativeActiveEnergyImportedAtIntervalStart?: Maybe<Scalars['Float']>;
  /** Cumulative values of the active energy export in Wh at the start of the interval */
  cumulativeActiveEnergyExportedAtIntervalStart?: Maybe<Scalars['Float']>;
  /** Cumulative values of the reactive energy import in varh at the start of the interval */
  cumulativeReactiveEnergyImportedAtIntervalStart?: Maybe<Scalars['Float']>;
  /** Cumulative values of the reactive energy export in varh at the start of the interval */
  cumulativeReactiveEnergyExportedAtIntervalStart?: Maybe<Scalars['Float']>;
  /** Report end time, in UTC */
  to: Scalars['Date'];
  /** Report start time, in UTC */
  from: Scalars['Date'];
};

export type ReturnDeviceInput = {
  deviceId: Scalars['String'];
  hasBeenUsed: Scalars['Boolean'];
  comment?: Maybe<Scalars['String']>;
};

export type ReturnDevicePayload = {
  __typename?: 'ReturnDevicePayload';
  device: DeviceInfo;
  success: Scalars['Boolean'];
};

/** Return information */
export type ReturnInfo = {
  __typename?: 'ReturnInfo';
  hasBeenUsed?: Maybe<Scalars['Boolean']>;
  returnedAt?: Maybe<Scalars['Date']>;
  comment?: Maybe<Scalars['String']>;
};

export type RevokeDeviceAccessInput = {
  deviceId: Scalars['String'];
  userId: Scalars['String'];
};

export type RushPricingDay = {
  __typename?: 'RushPricingDay';
  hours: HourRange;
  price: PriceSpecification;
};

/** The additional rush prices in a list, between two dates */
export type RushPricingPeriod = {
  __typename?: 'RushPricingPeriod';
  dateRange: DateRange;
  holidaysIncluded: Scalars['Boolean'];
  days: RushPricingWeek;
};

export type RushPricingWeek = {
  __typename?: 'RushPricingWeek';
  monday?: Maybe<RushPricingDay>;
  tuesday?: Maybe<RushPricingDay>;
  wednesday?: Maybe<RushPricingDay>;
  thursday?: Maybe<RushPricingDay>;
  friday?: Maybe<RushPricingDay>;
  saturday?: Maybe<RushPricingDay>;
  sunday?: Maybe<RushPricingDay>;
};

export type Search = {
  query?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['String']>;
  meterId?: Maybe<Scalars['String']>;
  externalUserId?: Maybe<Scalars['String']>;
};

/** There are different prices for summer and winter, day and night */
export type SeasonalPrices = {
  __typename?: 'SeasonalPrices';
  summer: PricesForPartOfYear;
  winter: PricesForPartOfYear;
};

/** There are different prices for summer and winter, but not day and night */
export type SeasonalPricesWithoutDayAndNight = {
  __typename?: 'SeasonalPricesWithoutDayAndNight';
  summer: PricesForPartOfYearWithoutDayAndNight;
  winter: PricesForPartOfYearWithoutDayAndNight;
};

/**
 * Energy model were users can select type of energy model.
 *
 * Companies might have multiple types of energy models, and
 * let users select which model they would like use.
 */
export type SelectableEnergyModel = {
  __typename?: 'SelectableEnergyModel';
  type: EnergyModelType;
  constantPrice?: Maybe<ConstantPrice>;
  differentPricesSeasonal?: Maybe<DifferentPricesSeasonal>;
  differentPricesSeasonalDayAndNight?: Maybe<DifferentPricesSeasonalDayAndNight>;
  differentPricesDayAndNight?: Maybe<DifferentPricesDayAndNight>;
};

export type SessionInfo = {
  __typename?: 'SessionInfo';
  accessToken: Scalars['String'];
  userId: Scalars['String'];
};

export type SessionInfoWithoutAccessToken = {
  __typename?: 'SessionInfoWithoutAccessToken';
  startOfSecret: Scalars['String'];
  userId: Scalars['String'];
};

export type SetCoordinatesForLocationInput = {
  coordinates: LatLngInput;
  locationId: Scalars['String'];
};

export type SetCoordinatesForLocationPayload = {
  __typename?: 'SetCoordinatesForLocationPayload';
  location: Location;
};

export type SetDeviceActivationStatusInput = {
  deviceIdentifier: DeviceIdentifierInput;
  activationStatus: ActivationStatus;
};

export type SetDeviceActivationStatusPayload = {
  __typename?: 'SetDeviceActivationStatusPayload';
  device: DeviceInfo;
  success: Scalars['Boolean'];
};

export type SetDeviceForLocationInput = {
  deviceIdentifier: DeviceQueryIdentifier;
  locationId: Scalars['String'];
};

export type SetDeviceForLocationPayload = {
  __typename?: 'SetDeviceForLocationPayload';
  location: Location;
};

export type SetIntermediaryWebhookSettingInput = {
  secret: Scalars['String'];
  intermediaryId?: Maybe<Scalars['String']>;
};

export type SetIntermediaryWebhookSettingPayload = {
  __typename?: 'SetIntermediaryWebhookSettingPayload';
  webhookSetting: IntermediaryWebhookSetting;
};

export type SetNameForLocationInput = {
  locationId: Scalars['String'];
  name: Scalars['String'];
};

export type SetNameForLocationPayload = {
  __typename?: 'SetNameForLocationPayload';
  location: Location;
};

export type ShipDeviceIdentifierInput = {
  claimId?: Maybe<Scalars['ClaimId']>;
  deviceId?: Maybe<Scalars['DeviceId']>;
};

export type ShipOrderInput = {
  deviceIdentifier: ShipDeviceIdentifierInput;
  orderId: Scalars['String'];
  trackingLink: Scalars['String'];
};

export type ShipToUserInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  postalAddress: PostalAddressInput;
  phoneNumber?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  externalUserId?: Maybe<Scalars['String']>;
  trackingLink?: Maybe<Scalars['String']>;
};

/** User information for tracking shipment and activation */
export type ShippedToUserInfo = {
  __typename?: 'ShippedToUserInfo';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  postalAddress: PostalAddress;
  phoneNumber?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  externalUserId?: Maybe<Scalars['String']>;
  trackingLink?: Maybe<Scalars['String']>;
};

export type SmartChargingConsideration = {
  __typename?: 'SmartChargingConsideration';
  isCharging: Scalars['Boolean'];
  isPluggedIn: Scalars['Boolean'];
  hasTimeEstimate: Scalars['Boolean'];
  recentlyAtChargingLocation: Scalars['Boolean'];
};

export type SmartChargingPlan = {
  __typename?: 'SmartChargingPlan';
  estimatedFinishAt: Scalars['String'];
  startAt?: Maybe<Scalars['String']>;
  stopConfirmedAt?: Maybe<Scalars['String']>;
  startConfirmedAt?: Maybe<Scalars['String']>;
  endedAt?: Maybe<Scalars['String']>;
  nonSmartCost: Scalars['Float'];
  smartCost?: Maybe<Scalars['Float']>;
};

export type SmartChargingPlanningStatusType =
  | 'PLAN_ENDED_DEADLINE_CHANGED'
  | 'PLAN_ENDED_DISABLED'
  | 'PLAN_ENDED_FAILED'
  | 'PLAN_ENDED_FINISHED'
  | 'PLAN_ENDED_UNPLUGGED'
  | 'PLAN_EXECUTING_CHARGE_INTERRUPTED'
  | 'PLAN_EXECUTING_STOPPED_AWAITING_PRICES'
  | 'PLAN_EXECUTING_STARTED'
  | 'PLAN_EXECUTING_STARTING'
  | 'PLAN_EXECUTING_START_FAILED'
  | 'PLAN_EXECUTING_STOPPED'
  | 'PLAN_EXECUTING_STOPPING'
  | 'PLAN_EXECUTING_STOP_FAILED'
  | 'PLAN_EXECUTING_OVERRIDDEN';

export type SmartChargingPolicy = {
  __typename?: 'SmartChargingPolicy';
  deadlineInUtcTime: Scalars['String'];
  isEnabled: Scalars['Boolean'];
};

export type SmartChargingStatusType =
  | 'DISABLED'
  | 'FULLY_CHARGED'
  | 'CONSIDERING'
  | 'PLANNED';

export type SubscriptionBilling = {
  __typename?: 'SubscriptionBilling';
  subscriptionType: SubscriptionType;
};

export type SubscriptionInput = {
  subscriptionType?: Maybe<SubscriptionType>;
};

export type SubscriptionType =
  | 'PERSONAL'
  | 'BUSINESS'
  | 'PILOT';

export type TestWebhookSettingInput = {
  callbackUrl: Scalars['String'];
  intermediaryId?: Maybe<Scalars['String']>;
};

export type TestWebhookSettingPayload = {
  __typename?: 'TestWebhookSettingPayload';
  success: Scalars['Boolean'];
};

/** Time zones, as reported by the _Internet Assigned Numbers Authority_ (IANA) database, but using an underscore for the forward slash. */
export type Timezone =
  | 'EUROPE_OSLO'
  | 'UTC';

export type UpdateChargingLocationInput = {
  id: Scalars['ID'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
};

export type UpdateChargingLocationPayload = {
  __typename?: 'UpdateChargingLocationPayload';
  chargingLocation: ChargingLocation;
};

export type UpdateEstimatedHourlyActiveEnergyLimitWarningAlertSettingInput = {
  alertSettingId: Scalars['ID'];
  limitInWattHours: Scalars['Int'];
};

export type UpdateEstimatedHourlyActiveEnergyLimitWarningAlertSettingPayload = {
  __typename?: 'UpdateEstimatedHourlyActiveEnergyLimitWarningAlertSettingPayload';
  alertSetting: EstimatedHourlyActiveEnergyLimitWarningAlertSetting;
};

export type UpdateHourlyConsumptionLimitEstimationWarningAlertSettingInput = {
  alertSettingId: Scalars['ID'];
  limitInWatts: Scalars['Int'];
};

export type UpdateHourlyConsumptionLimitEstimationWarningAlertSettingPayload = {
  __typename?: 'UpdateHourlyConsumptionLimitEstimationWarningAlertSettingPayload';
  alertSetting: HourlyConsumptionLimitEstimationWarningAlertSetting;
};

export type UpdateJedlixChargingLocationInput = {
  chargingLocationId: Scalars['String'];
  coordinates: LatLngInput;
  address: JedlixAddressInput;
};

export type UpdateSmartChargingPolicyInput = {
  /**
   * Time for when the charging should be finished by.
   * hours:minutes, e.g. 08:00 or 23:59, in UTC time
   */
  deadlineInUtcTime: Scalars['String'];
  isEnabled: Scalars['Boolean'];
  vehicleId: Scalars['String'];
};

export type UpdateSmartChargingPolicyPayload = {
  __typename?: 'UpdateSmartChargingPolicyPayload';
  smartChargingPolicy: SmartChargingPolicy;
};

export type User = {
  __typename?: 'User';
  id: Scalars['String'];
  verifiedIdentities?: Maybe<Array<Identity>>;
};

export type UserIdentifier = ExternalUserIdentifier;

export type UserIdentifierInput = {
  externalUser?: Maybe<ExternalUserIdentifierInput>;
};

export type UserInfo = {
  __typename?: 'UserInfo';
  alertSettings?: Maybe<Array<AlertSetting>>;
  chargingLocations?: Maybe<Array<ChargingLocation>>;
  id: Scalars['ID'];
  intermediaries?: Maybe<Array<IntermediaryInfo>>;
  linkedIntegrationVendors?: Maybe<Array<IntegrationVendorInfo>>;
  verifiedEmail?: Maybe<Scalars['String']>;
  verifiedIdentities?: Maybe<Array<Identity>>;
  sessions?: Maybe<Array<SessionInfoWithoutAccessToken>>;
  userIdentifier?: Maybe<UserIdentifier>;
};


export type UserInfoIntermediariesArgs = {
  filter?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserInput = {
  userIdentifier: UserIdentifierInput;
};

export type UserNotAuthenticatedError = CustomError & {
  __typename?: 'UserNotAuthenticatedError';
  message: Scalars['String'];
  type: ErrorType;
};

export type UserNotPermittedError = CustomError & {
  __typename?: 'UserNotPermittedError';
  message: Scalars['String'];
  type: ErrorType;
  resourceId?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
};

export type UserPayload = {
  __typename?: 'UserPayload';
  user: UserInfo;
};

export type VehicleCapabilities = {
  __typename?: 'VehicleCapabilities';
  geoLocation: Scalars['Boolean'];
  desiredStateOfCharge: Scalars['Boolean'];
  stateOfCharge: Scalars['Boolean'];
  chargeState: Scalars['Boolean'];
  pullTelemetry: Scalars['Boolean'];
  startStopCharging: Scalars['Boolean'];
  charge: Scalars['Boolean'];
};

export type VehicleChargingStatus =
  | 'UNKNOWN'
  | 'COMPLETE'
  | 'CHARGING'
  | 'DISCONNECTED'
  | 'STOPPED';

export type VehicleInfo = {
  __typename?: 'VehicleInfo';
  batteryCapacity: Scalars['Float'];
  brand: JedlixBrand;
  range: Scalars['Float'];
  model: Scalars['String'];
  version: Scalars['String'];
};

export type VehicleSmartChargingStatusInput = {
  vehicleId: Scalars['String'];
};

export type VehicleSmartChargingStatusPayload = DisabledSmartChargingStatus | ConsideringSmartChargingStatus | PlannedSmartChargingStatus | FullyChargedSmartChargingStatus;

export type Vendor =
  | 'MILL'
  | 'ADAX';


/**
 * Capacity model used by Fjellnett/Eidefoss
 *
 * Computation steps, according to <https://www.fjellnett.no/privatkunder/category1153.html>:
 *
 * - Fetch consumption data for each hour for the previous twelve months.
 * - Multiply the consumption for each hour with the corresponding month weights.
 * - Find the peak value of every week.
 * - Pick the highest (5) `weekPeaksPerYear` and compute the average for these (with two decimals).
 * - Multiply by `pricePerAverageKwh`.
 * - Add `baseFeePerYear`.
 * - Divide by 12.
 */
export type WeightedYearlyRollingPeaksInDifferentWeeks = {
  __typename?: 'WeightedYearlyRollingPeaksInDifferentWeeks';
  type: CapacityModelType;
  baseFeePerYear: PriceSpecification;
  monthWeights: MonthWeights;
  pricePerKwhForAverageOfPeaks: PriceSpecification;
  weekPeaksPerYear: Scalars['Int'];
};

export type ZaptecClaimInstallationInput = {
  installationId: Scalars['String'];
};

export type ZaptecClaimInstallationPayload = {
  __typename?: 'ZaptecClaimInstallationPayload';
  success: Scalars['Boolean'];
};

export type CommonSignInMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type CommonSignInMutation = (
  { __typename?: 'Mutation' }
  & { commonSignIn: (
    { __typename?: 'CommonSignInPayload' }
    & Pick<CommonSignInPayload, 'success'>
  ) }
);

export type ZaptecClaimInstallationMutationMutationVariables = Exact<{
  installationId: Scalars['String'];
}>;


export type ZaptecClaimInstallationMutationMutation = (
  { __typename?: 'Mutation' }
  & { zaptecClaimInstallation: (
    { __typename?: 'ZaptecClaimInstallationPayload' }
    & Pick<ZaptecClaimInstallationPayload, 'success'>
  ) }
);


export const CommonSignInDocument = gql`
    mutation CommonSignIn($username: String!, $password: String!) {
  commonSignIn(input: {username: $username, password: $password, vendor: MILL}) {
    success
  }
}
    `;
export type CommonSignInMutationFn = Apollo.MutationFunction<CommonSignInMutation, CommonSignInMutationVariables>;

/**
 * __useCommonSignInMutation__
 *
 * To run a mutation, you first call `useCommonSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommonSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commonSignInMutation, { data, loading, error }] = useCommonSignInMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCommonSignInMutation(baseOptions?: Apollo.MutationHookOptions<CommonSignInMutation, CommonSignInMutationVariables>) {
        return Apollo.useMutation<CommonSignInMutation, CommonSignInMutationVariables>(CommonSignInDocument, baseOptions);
      }
export type CommonSignInMutationHookResult = ReturnType<typeof useCommonSignInMutation>;
export type CommonSignInMutationResult = Apollo.MutationResult<CommonSignInMutation>;
export type CommonSignInMutationOptions = Apollo.BaseMutationOptions<CommonSignInMutation, CommonSignInMutationVariables>;
export const ZaptecClaimInstallationMutationDocument = gql`
    mutation ZaptecClaimInstallationMutation($installationId: String!) {
  zaptecClaimInstallation(input: {installationId: $installationId}) {
    success
  }
}
    `;
export type ZaptecClaimInstallationMutationMutationFn = Apollo.MutationFunction<ZaptecClaimInstallationMutationMutation, ZaptecClaimInstallationMutationMutationVariables>;

/**
 * __useZaptecClaimInstallationMutationMutation__
 *
 * To run a mutation, you first call `useZaptecClaimInstallationMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useZaptecClaimInstallationMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [zaptecClaimInstallationMutationMutation, { data, loading, error }] = useZaptecClaimInstallationMutationMutation({
 *   variables: {
 *      installationId: // value for 'installationId'
 *   },
 * });
 */
export function useZaptecClaimInstallationMutationMutation(baseOptions?: Apollo.MutationHookOptions<ZaptecClaimInstallationMutationMutation, ZaptecClaimInstallationMutationMutationVariables>) {
        return Apollo.useMutation<ZaptecClaimInstallationMutationMutation, ZaptecClaimInstallationMutationMutationVariables>(ZaptecClaimInstallationMutationDocument, baseOptions);
      }
export type ZaptecClaimInstallationMutationMutationHookResult = ReturnType<typeof useZaptecClaimInstallationMutationMutation>;
export type ZaptecClaimInstallationMutationMutationResult = Apollo.MutationResult<ZaptecClaimInstallationMutationMutation>;
export type ZaptecClaimInstallationMutationMutationOptions = Apollo.BaseMutationOptions<ZaptecClaimInstallationMutationMutation, ZaptecClaimInstallationMutationMutationVariables>;